import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog-session.component.html',
    styleUrls: ['./confirm-dialog-session.component.scss']
})
export class sessionConfirmDialogComponent {
    public confirmMessage: string;


    /**
     * Constructor
     *
     * @param {MatDialogRef<sessionConfirmDialogComponent>} dialogRef
     */
    onClose: {};
    constructor(public dialog: MatDialog,
        public dialogRef: MatDialogRef<sessionConfirmDialogComponent>
    ) {
    }
    close() {
        this.dialogRef.close(true)
        this.onClose = { data: 'true' };
    }

}
