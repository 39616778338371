import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDatepickerModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewPodDialogComponent } from './view-pod-dialog.component';

@NgModule({
    declarations: [
        ViewPodDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        FormsModule
    ],
    entryComponents: [
        ViewPodDialogComponent
    ],
})
export class ViewPodDialogModule
{
}
