import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource } from '@angular/material';
import { enableDebugTools } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { APPCONFIG } from 'app/main/config';
import { HttpService } from 'app/main/services/http.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AbortJobDialog } from '../abort_job/abort_job.component';

@Component({
    selector: 'app-satellite-and-carrier',
    templateUrl: './satellite-and-carrier.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls: ['./satellite-and-carrier.component.scss?v=${APPCONFIG.cacheVersion}']
})
export class SatelliteAndCarrierComponent implements OnInit {
    readonly main_app_logo = APPCONFIG.main_app_logo;
    @ViewChild(SignaturePad) signaturePad: SignaturePad;
    @ViewChild('inputFile') myInputVariable: ElementRef;
    uploadedImage;
    confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
    result;
    title;
    currentDeliveryNumber:any;
    maxImageWidth=650;
    fileType: any;
    selectedFileName: string;
    sign: boolean = false;
    dataImg;
    loginForm: FormGroup;
    loginFormErrors: any;
    vehicleId;
    jobStatus: any;
    postDataClear: boolean = false;
    jobData;
    jobApiData: any;
    profileForm: any;
    jobInputs: boolean = true;
    allPodCaptured: any;
    job;
    imageSrc:any;
    deliveryNumberInput: string;
    message: string;
    detailPage: boolean = false;//false
    detailPageSubmit: any;
    nextEnabled: boolean = false;//false
    deliveryConfirmed = false;//false
    deliveredQty: string = '';
    instructions: string = '';
    deliveryLineItemColumns = ["description", "quantity", "deliveredQuantity"];
    deliveryDetails = [];
    deliveryPodDetails=[];
    allDeliveryNumbers='';
    combinedPod=false;
    isAdmin=false;
    docUploadEnabled=false;
    validJob=false;

    // Private
    private _unsubscribeAll: Subject<any>;
    deliveryLineItems: any;
    enableContinueBtn: boolean;
    name: string;
    email: string;
    count: any;
    reasonCodeData: any;
    imageType: string;
    eSignFlag: boolean = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public _matDialogRef: MatDialogRef<any>,
        public dialog: MatDialog,
        private http1: HttpService,
        private spinner: NgxSpinnerService,
        private _router: Router,
        private abortjobDialog: AbortJobDialog,
        private ng2ImgMax: Ng2ImgMaxService,
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder, private http: HttpClient,
        private cdr:ChangeDetectorRef
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the defaults
        this.loginFormErrors = {
            email: {},
            password: {}
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    onForm() {
        var jobId = '';
        var postCode = '';

        try {
            jobId = localStorage.getItem("j");
            jobId = jobId?jobId:'';
            
            postCode = localStorage.getItem("p");
            postCode = (postCode && jobId)?postCode:'';

            if(postCode || jobId) {
                this.deliveryNumberInput = postCode?jobId:'';
            }
            localStorage.removeItem("j");
            localStorage.removeItem("p");

            this.isAdmin = (jobId && postCode)?true:false;
        }catch(err){
            console.error(err);
        }

        this.profileForm = new FormGroup({
            deliveryNo: new FormControl(this.deliveryNumberInput, Validators.required),
            postCode: new FormControl(postCode, Validators.required),
            jobPkId: new FormControl(jobId)
        })
    }

    drawComplete() {
        //   debugger;
        // will be notified of szimek/signature_pad's onEnd event
        /* //console.log(this.signaturePad.toDataURL());
        //console.log(this.signaturePad.fromData); */

    }

    drawStart() {
        // will be notified of szimek/signature_pad's onBegin event
        this.sign = true;
        this.imageType = "signed"
    }
    ngOnInit(): void {
        this.signaturePadOptions;
        this.deliveryNumberInput = this.route.snapshot.paramMap.get('deliveryNumber');
        this.deliveryNumberInput = this.deliveryNumberInput?this.deliveryNumberInput:'';
        this.onForm();
        this.docUploadEnabled = this.isAdmin;
        if(this.isAdmin) {
            this.adminLogon();
        }
    }

    deliveredQuantityChange(userSelection, rowData) {
        userSelection = userSelection.value;
        if (rowData.quantity != userSelection) {
            const dialogRef = this.dialog.open(AbortJobDialog, {
                disableClose: true,
                data: { deliveryReason: true, rowCounter: rowData, abortJob: false, isSecondaryFreight: false}
            });
            dialogRef.afterClosed().subscribe(result => {
                result.data ? this.reasonCodeData = result.reasonCode : this.reasonCodeData.reasonCode = '';
                this.deliveryDetails.forEach(ele => {
                    if (ele.itemId == rowData.itemPrimaryId) {
                        ele.reasonId = this.reasonCodeData;
                        ele.actualQuantity = Number(userSelection)
                    }
                })
            });

        } else {
            this.deliveryDetails.forEach(ele => {
                if (ele.itemId == rowData.itemPrimaryId) {
                    ele.reasonId = '';
                    ele.actualQuantity = Number(userSelection)
                }
            })
        }
        //console.log('deliveryDetails', this.deliveryDetails)
    }

    validateDeliveries() {
        for (var i = 0; i < this.jobApiData['items'].length; ++i) {
            if (this.jobApiData['delivery'][i].quantity > this.jobApiData['delivery'][i].delQty && !this.jobApiData['delivery'][i].reasonCode) {
                this.enableContinueBtn = false;
                return false;
            }
        }
        this.enableContinueBtn = true;
    }
    deliveryConfirmedAction() {
        this.deliveryConfirmed = true;
    }

    public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
        'minWidth': 2,
        'canvasWidth': 300,
        'canvasHeight': 200,
        backgroundColor: '#ffffff',
        signaturePad: { _ctx: { fillStyle: '#ffffff', } },
        background: '#ffffff',
        fillStyle: '#ffffff',
        penColor: 'rgb(0, 0, 0)',
    };

    clear() {
        if(this.eSignFlag && this.signaturePad) {
            this.signaturePad.clear();
            this.signaturePadOptions;
        }
        this.sign = false;
    }
    abortJob(): void {
        const dialogRef = this.dialog.open(AbortJobDialog, {
            data: { jobId: this.jobApiData.jobPkId, abortJob: true, isSecondaryFreight: false, isAdmin: this.isAdmin}
        });
        dialogRef.afterClosed().subscribe(result => {
            this.resetPage();
            this.profileForm.reset();
            if (result.data == "true") {
                if(this.signaturePad) {
                    this.signaturePad.clear();
                    this.signaturePadOptions;
                }
                this.onForm();
            }
        });
    }

    receiveMessage($event) {
        this.message = $event
    }

    submitDeliveryData() {
        if(!this.allPodCaptured && !this.sign) {
            return;
        }

        this.spinner.show();
        this.dataImg = null;
        let token = this.isAdmin ? JSON.parse(localStorage.getItem('Token')) : 'ou*7nMm95ON0s??6FX0/[J1PQt%Lwj0I';
        let parUrl = this.isAdmin ? '/epw/syncJobForAdmin' : '/epw/syncJob';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'idToken': token
            })
        };
        let deliveryPodCleanedDetails=[];
        if(this.eSignFlag && this.sign && this.signaturePad) {
            this.dataImg = this.signaturePad.toDataURL('image/jpeg');
        }
        else if(this.allPodCaptured) {
            deliveryPodCleanedDetails = this.cleanPodDeliveryDetails();
        }
        let user = {jobPkId:this.jobApiData.jobPkId, instructions:this.instructions, worksmanName:this.name, email:this.email, epwImage:this.dataImg, deliveryPodDetails:deliveryPodCleanedDetails, deliveryDetails:this.deliveryDetails };
        let body = JSON.stringify(user);
        this.http.post(APPCONFIG.appUrl + parUrl, user, httpOptions)
            .subscribe(data => {
                if (data) {
                }
                this.result = data;
                if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201 || this.result.statusCode == 1) {
                    this.spinner.hide();
                    if (this.eSignFlag && this.signaturePad) {
                        this.signaturePad.clear();
                        this.signaturePadOptions;
                    }
                    this.sign=false;
                    this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                    this.confirmDialogRefSession.componentInstance.confirmMessage = `Job Completed`;
                    this.confirmDialogRefSession.afterClosed().subscribe(result => {
                        this.detailPage = false;
                        this.nextEnabled = false;
                        this.profileForm.reset();
                        this.profileForm.patchValue({deliveryNo: this.deliveryNumberInput});
                        this.resetPage();
                    });
                }
            },
                err => {
                    this.postDataClear = false;
                    let title = "Failed to submit data";
                    this.http1.errorHandler(err, title);
                    this.spinner.hide();
                });

    }
    resetPage() {
        this.combinedPod=false;
        this.deliveryConfirmed=false;
        this.email='';
        this.detailPage = false;
        this.nextEnabled = false;
        this.allPodCaptured=false;
        this.deliveryDetails = [];
        this.deliveryPodDetails=[];
        this.allDeliveryNumbers='';
        this.sign=false;
        this.name = '';
        this.instructions = '';
        (this.eSignFlag && this.signaturePad) ? this.signaturePad.clear() : "";
    }
    colorDisabled(allPodCaptured) {
        switch (allPodCaptured) {
            case true:
                return '#03a9f4';
            case false:
                return 'rgba(0, 0, 0, 0.12) ';
            case undefined:
                return 'rgba(0, 0, 0, 0.12)  '
        }
    }
    ngAfterViewChecked() {
        if (this.postDataClear) {
            this.jobApiData = null;
        }
        if (this.jobApiData) {
            if (this.jobApiData.jobStatus == 'Completed' || (this.jobApiData.jobStatus=='Cancelled' && !this.jobApiData.epwAbort)) {
                this.allPodCaptured = false;
                (this.eSignFlag && this.signaturePad) ? this.signaturePad.off() : "";
                this.sign=false;
            }
        }
    }
    onReset() {
        if (this.eSignFlag && this.signaturePad) {
            this.signaturePad.clear();
            this.signaturePad.on();
            this.signaturePad.clear();
            this.signaturePadOptions;
        }
        this.sign=false;
        this.name = '';
        this.email='';
        this.myInputVariable.nativeElement.value = '';
        this.resetDeliveryPodDetails();
    }
    close() {
        this.onForm();
        this.jobApiData = {};
        this.detailPage = false;
        this.detailPageSubmit = false;
        this.allPodCaptured = false;
        this.sign = false;
        this.postDataClear = true;
        this.onForm();
    }


    loginSubmit() {
        if (this.profileForm.value.deliveryNo && this.profileForm.value.postCode) {
            this.spinner.show();
            this.carrierLogon();
        }
    }
    carrierLogon() {
        let body = JSON.stringify(this.profileForm.value);
        let token = 'ou*7nMm95ON0s??6FX0/[J1PQt%Lwj0I';
        this.logon('/epw/getJob', body, token);
    }
    adminLogon() {
        let body = JSON.stringify(this.profileForm.value);
        let token = JSON.parse(localStorage.getItem('Token'));
        this.logon('/epw/getJobForAdmin', body, token);
    }
    logon(url, body, token) {
        this.resetPage();
        this.spinner.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'idToken': token
            })
        };
        this.http.post(APPCONFIG.appUrl+url, body, httpOptions)
        .subscribe(data => {
            if (data) {
                this.spinner.hide();
            }
            this.job = data;
            this.title = "";
            if( (!this.isAdmin && !this.job.data.carrier) || (this.isAdmin && !(this.job.data.epwAbort || this.job.data.carrier)) ) {
                this.title = `You are not authorised to access or complete this delivery`;
            }
            else if (this.job.data.jobStatus!=='Assigned' && !this.job.data.epwAbort) {
                this.title = `This job is ${this.job.data.jobStatus}`;
            }
            
            if(this.title) {
                this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;
                this.confirmDialogRefSession.afterClosed().subscribe(result => {
                    this.detailPage = false;
                    this.nextEnabled = false;
                    this.profileForm.reset();
                });
            }
            else if(this.job.httpStatusCode == 200 || this.job.httpStatusCode == 201 || this.job.statusCode == 1) {
                this.validJob=true;
                this.detailPage = true;
                this.detailPageSubmit = true;
                this.postDataClear = false;
                
                this.jobApiData = this.job.data;
                this.deliveryLineItems = new MatTableDataSource(this.jobApiData['items']);
                this.eSignFlag = this.jobApiData['electronicSignature'];
                this.jobApiData['items'].forEach((element, i) => {
                    this.deliveryDetails.push({ "itemId": element.itemPrimaryId, "expectedQuantity": element.quantity, "actualQuantity": element.quantity, "reasonId": "" })
                });
                this.resetDeliveryPodDetails();
            }
        },
            err => {
                let title = "";
                if (err.error.statusCode == 2 && err.error.httpStatusCode == 400) {
                    this.title = "Invalid Details.";
                    this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                    this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;
                    this.spinner.hide();
                }
                else {
                    this.http1.errorHandler(err, title);
                } this
                this.spinner.hide();
            }

        );
    }
    readURL(event: Event, imageType: string, deliveryNumber: string): void {
        this.currentDeliveryNumber=deliveryNumber;
        this.fileType='';
        this.selectedFileName = '';
        this.imageType = imageType;
        this.spinner.show();
        if (event.type == 'click') {
            this.spinner.hide();
            return;
        }
        if ((<HTMLInputElement>event.target).files && (<HTMLInputElement>event.target).files[0]) {
            const file = (<HTMLInputElement>event.target).files[0];
            this.selectedFileName = file.name;

            if(file.type=='application/pdf') { //PDF
                this.fileType='pdf';
            }else {
                this.fileType='jpg';
            }
            if (typeof (FileReader) !== 'undefined') {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updateDeliveryPodDetails(this.currentDeliveryNumber, reader.result);
                    this.spinner.hide();
                    this.cdr.detectChanges();
                };
                reader.readAsDataURL(file);
            }
        }
    }

    resizeImageCallback(img, $this) {
        $this.imageSrc = $this.steppedScale(img, $this.maxImageWidth, 0.9);
        $this.updateDeliveryPodDetails($this.currentDeliveryNumber, $this.imageSrc);
        $this.spinner.hide();
        $this.cdr.detectChanges();
    }

    base64ToImage(base64img, callback, $this) {
        var img = new Image();
        img.onload = function() {
            callback(img, $this);
        };
        img.src = base64img;
    }

    steppedScale(img, maxWidth, step) {
        var canvas = document.createElement('canvas'),
          ctx = canvas.getContext("2d"),
          oc = document.createElement('canvas'),
          octx = oc.getContext('2d');
      
        canvas.width = maxWidth; // destination canvas size
        canvas.height = canvas.width * img.height / img.width;
      
        if (img.width * step > maxWidth) { // For performance avoid unnecessary drawing
          var mul = 1 / step;
          var cur = {
            width: Math.floor(img.width * step),
            height: Math.floor(img.height * step)
          }
      
          oc.width = cur.width;
          oc.height = cur.height;
      
          octx.drawImage(img, 0, 0, cur.width, cur.height);
      
          while (cur.width * step > maxWidth) {
            cur = {
                width: Math.floor(cur.width * step),
              height: Math.floor(cur.height * step)
            };
            octx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height);
          }
      
          ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
        } else {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
        
        return canvas.toDataURL();
      
        // -- display canvas used for scaling --
        //document.getElementById("scale-canvas").src = oc.toDataURL();
    }

    filterDeliveryPodDetails() {
        if(this.combinedPod) {
            this.deliveryPodDetails = [{ "isAllDelivery":true, "deliveryNumber": "1", "pod":{"uploaded":[],"captured":[]}}];
        }
        return this.deliveryPodDetails;
    }

    resetDeliveryPodDetails() {
        this.allDeliveryNumbers='';
        this.allPodCaptured = false;
        this.deliveryPodDetails=[];
        this.jobApiData['deliveryNoteNumber'].forEach((element, j) => {
            this.deliveryPodDetails.push({ "deliveryNumber": element, "pod":{"uploaded":[],"captured":[]}}); //{"podSrc": "", "podFileType": "", "podSrcType": ""}
            if(this.allDeliveryNumbers) {
                this.allDeliveryNumbers = this.allDeliveryNumbers + ", "
            }
            this.allDeliveryNumbers = this.allDeliveryNumbers + element;
        });
        this.filterDeliveryPodDetails();
    }

    updateDeliveryPodDetails(deliveryNumber, fileSrc) {
        let flag = true;
        this.deliveryPodDetails.forEach(ele => {
            if (ele.deliveryNumber == deliveryNumber) {
                ele.pod[this.imageType].push({"podSrc": fileSrc, "podFileType":this.fileType, "podSrcType":this.imageType});
            }
            if(!ele.pod || (ele.pod.uploaded.length==0 && ele.pod.captured.length==0)) {
                flag=false;
            }
        })
        this.allPodCaptured = flag;
    }

    cleanPodDeliveryDetails() {
        let arr=[];
        this.deliveryPodDetails.forEach(ele => {
            ele.pod.uploaded.forEach(podDetail => {
                if (podDetail.podFileType=='pdf') {
                    podDetail.podSrc = podDetail.podSrc.replace(/^data:application\/\w+;base64,/, "");
                }
                else if (podDetail.podFileType=='jpg') {
                    podDetail.podSrc = podDetail.podSrc.replace(/^data:image\/\w+;base64,/, "");
                }
                arr.push({"deliveryNumber":ele.deliveryNumber,"podSrc": podDetail.podSrc, "podFileType": podDetail.podFileType, "podSrcType": podDetail.podSrcType});
            })
            ele.pod.captured.forEach(podDetail => {
                if (podDetail.podFileType=='pdf') {
                    podDetail.podSrc = podDetail.podSrc.replace(/^data:application\/\w+;base64,/, "");
                }
                else if (podDetail.podFileType=='jpg') {
                    podDetail.podSrc = podDetail.podSrc.replace(/^data:image\/\w+;base64,/, "");
                }
                arr.push({"deliveryNumber":ele.deliveryNumber,"podSrc": podDetail.podSrc, "podFileType": podDetail.podFileType, "podSrcType": podDetail.podSrcType});
            })
        })
        return arr;
    }

    podToggled() {
        this.resetDeliveryPodDetails();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    enableNext() {
        this.nextEnabled = true;
    }
    backToDetails() {
        this.nextEnabled = !this.nextEnabled;
        this.deliveryConfirmed = !this.deliveryConfirmed;
    }
}

