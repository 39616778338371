import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import date_box from '../../../../node_modules/devextreme/ui/date_box';
import {MatPaginator, MatSort, MatTableDataSource, PageEvent} from '@angular/material';
import * as shape from 'd3-shape';
import { DatePipe, NgStyle } from '@angular/common';
import { Color, BaseChartDirective } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APPCONFIG } from '../config';
import { HttpService } from '../services/http.service';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import {MatDialog,MatDialogRef,MatDialogModule} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-live-dashboard',
  templateUrl: './live-dashboard.component.html',
  styleUrls: ['./live-dashboard.component.scss'],
  providers: [ HttpService,DatePipe],
})
export class LiveDashboardComponent implements OnInit {
    single: any[];
    multi: any[];
    depotsResult;
    interval_id: any;
    _intervalLoading;
    interval_idUnpack;
    interval_idSuccessPOD;
    interval_idOnRouteArrival;
    interval_idTimeSlotPerform;
    interval_idEtaToDepot;
    interval_idFailedResionCode;
    vehicleResult;
    nodataReasonCodeApiData:boolean = false;
    nodataSuccessPODApiData:boolean = false;
    nodataUnpackFailedApiData:boolean = false;
    nodataOnRouteArrivalApiData:boolean = false;
    dataSourceEtaToDepot: MatTableDataSource<any>;
    dataSourceOnRouteArrival: MatTableDataSource<any>;
    dataSourceTop3FailureCodes: MatTableDataSource<any>;
    displayedColumns = ['vehicleRegId','eta', 'user' ,  'remarks'];
    displayedColumnsOnRouteArrival =  ['vehicleRegId', 'user','deliveryTimeVariance', 'count' ]
    displayedColumnsTopFailureCodes = ['codes', 'qty', 'failPercentage'];
    vehicleDisable;
    todaysDate = new Date();
    selected: 'Vehicle';
    view: any[] = [900, 400];
    filter;
    selectedFilter;
    selectedFilterUnpack;
    selectedFilterPODSuccess;
    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Country';
    showYAxisLabel = true;
    yAxisLabel = 'Population';
    public loading = false;
    loadingFailedResionCode = false;
    loadingUnpack = false;
    loadingSuccessPOD = false;
    loadingServiceLevel = false;
    loadingTopFailureCodes = false;
    loadingOnRouteArrival = false;
    loadingTimeSlotPerform = false;
    loadingEtaToDepot = false;
   
    colorScheme = {
      domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };
 // view: string;
  card9Expanded: boolean;
  card10Expanded: boolean;
  card19: any;
  card24: any;
  card25: any;
  card26: any;
  filterSelection:any;
  depots:any;
  vehicles:any;
  selectedFilterTimeSlot;
  category:any;
  selectedDepot:any;
  selectedVehicle:any;
  selctedCategory:any;
  range:Range = {fromDate:new Date(), toDate: new Date()};
  options:NgxDrpOptions;
  getlength = 100;
  presets:Array<PresetItem> = [];
  tableDemoData:any;
  dataSource: MatTableDataSource<any>;
  confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
   progress1: Observable<number>;
   progressJF31: Observable<number>;
   progressUnpack: Observable<number>;
   progressTimeSlot: Observable<number>;
  
  
  constructor(private http: HttpClient, private datePipe: DatePipe, private http1: HttpService,
    private spinner: NgxSpinnerService,) {
    if (this.http1.checkSession()) {
    }
    else {
      this.http1.showSessionError();
    } 
    
  }
    

  ngOnInit() {
   
    this.filterSelected();
    const today = new Date();
  
    
    this.getCombineDepotData();
    this.setupPresets();
    
    this.options = {
                    presets: this.presets,
                    format: 'mediumDate',
                    range: {fromDate:today, toDate: today},
                    applyLabel: "Submit",
                    calendarOverlayConfig: {
                      shouldCloseOnBackdropClick: false
                    }
                    
                  };
                  
  }
  emulateProgressUnpack() {
    return new Observable<number>(observer => {
      let val = 0;
      const interval = setInterval(() => {
        if (val < this.UnpackPercentage) { 
          val++;
        } 

        observer.next(val);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    });
  }
  emulateProgressTimeSlot() {
    return new Observable<number>(observer => {
      let val = 0;
      const interval = setInterval(() => {
        if (val < this.timeSlotPercentage) { 
          val++;
        } 

        observer.next(val);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    });
  }
  emulateProgress1() {
    return new Observable<number>(observer => {
      let val = 0.0;
      const interval = setInterval(() => {
        if (val < this.serviceLevelPersentage) {
          val++;
        } 

        observer.next(val);
      }, 100);

      return () => { 
        clearInterval(interval);
      };
    });
  }
  emulateProgressJF31() {
    return new Observable<number>(observer => {
      let val = 0;
      const interval = setInterval(() => {
        if (val < this.JF31Persentage) {
          val++;
        } 

        observer.next(val);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    });
  }
  
  getDepotData() {
    let uid = JSON.parse(localStorage.getItem('uid'));
    let token = JSON.parse(localStorage.getItem('Token'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'idToken': token
      })
    };
    //return this.http.get(APPCONFIG.appUrl + '/depot/roleBasedDepot/' + uid, httpOptions);
    return this.http.get(APPCONFIG.appUrl + '/report/roleBasedDepot/' + uid, httpOptions);
  }
  
  
  getCombineDepotData() {
    //this.spinner.show();
    this.getDepotData().subscribe(result => {
      this.depotsResult = result;
      this.depots = this.depotsResult.data;
      console.log(this.depots);
      this.selectedDepot = this.depots[0].depotId;
      
     
      
      this.postDataOnRouteArrival();
      this.postDataEtaToDepot();
      this.postDataServiceLevel();
      this.postDataJF31();
      this.postDataTimeSlot();
      this.postDataUnpack();
      this.postDataFailCodes();
    });
  }
  getApiDataDepot(selectedDepot){
    this.filterSelected();
    
    let promise = new Promise((resolve, reject) => {
      Promise.all([
      
    ]).then(
        () => {
            resolve();
        },
        reject
    );
    });
    
    
    //this.spinner.show();
   
      this.postDataOnRouteArrival();
      this.postDataEtaToDepot();
      this.postDataServiceLevel();
      this.postDataJF31();
      this.postDataTimeSlot();
      this.postDataUnpack();
      this.postDataFailCodes();
 
    return promise;
  }
  reasonCodeApi;
  reasonCodeApiData;
  unpackFailedApi;
  unpackFailedApiData;
  arrayUnpack:any[] = [];
  
  onRouteArrivalApi;
  onRouteArrivalApiData;
  public barChartOptionsOnRouteArrival:any = {
    scaleShowVerticalLines: true,
    responsive: true,
    maintainAspectRatio: true,

    legend: {
        display: false,
    },
  };
  public barChartLabelsOnRouteArrival:string[] = [];
  public barChartTypeOnRouteArrival:string = 'bar';
 // public barChartLegendUnpackFailed:boolean = true;
  public chartColorsOnRouteArrival: Array<any> = [
    { // first color
      backgroundColor: 'rgba(229,3,66,1)',
      borderColor: 'rgba(229,3,66,1)',
      pointBackgroundColor: 'rgba(229,3,66,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(229,3,66,1)'
    },
    { // second color
      backgroundColor: 'rgba(3,198,229,1)',
      borderColor: 'rgba(3,198,229,1)',
      pointBackgroundColor: 'rgba(3,198,229,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(3,198,229,1)'
    },
    { // Third color
        backgroundColor: 'rgba(142,3,229,1)',
        borderColor: 'rgba(142,3,229,1)',
        pointBackgroundColor: 'rgba(142,3,229,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(142,3,229,1)'
      }];
  public barChartDataOnRouteArrival:any[] = [
    {data: [25], label: 'On-Time'},
    {data: [28 ], label: 'Early'},
    {data: [28 ], label: 'Delayed'}
  ];
  arrayOnRouteArrival:any = [];
  selectdepotId;
  postDataOnRouteArrival() {
    this.loadingOnRouteArrival = true;
    var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
  this.arrayOnRouteArrival = [];
  
  let user = {
      depotId: this.selectedDepot!=-1?this.selectedDepot:'',
      tripDate: date

  }

  let body = JSON.stringify(user);
  let token = JSON.parse(localStorage.getItem('Token'));
  
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'idToken': token
    })
  };
  
  this.http.post(APPCONFIG.appUrl + '/dashboard/onRouteArrival', body, httpOptions)
    .subscribe(data => {
      if(data){
        if(this.interval_idOnRouteArrival){
          clearTimeout(this.interval_idOnRouteArrival);
        }
        this.interval_idOnRouteArrival = setTimeout(()=>{
          this.postDataOnRouteArrival()}, 120000)
      }
      /* console.log("on route code Start");
        console.log(data);
        console.log("on route code end"); */
        this.onRouteArrivalApi = data;
        this.onRouteArrivalApiData = this.onRouteArrivalApi.data;
        this.dataSourceOnRouteArrival = new MatTableDataSource(this.onRouteArrivalApiData);
        this.loadingOnRouteArrival = false;
    },
    err => {
      if(err){
        if(this.interval_idOnRouteArrival){
          clearTimeout(this.interval_idOnRouteArrival);
        }
        this.interval_idOnRouteArrival = setTimeout(()=>{
          this.postDataOnRouteArrival()}, 120000)
      }
      this.dataSourceOnRouteArrival = new MatTableDataSource(null);
      this.loadingOnRouteArrival = false;
    });
}
topFailureCodes;
topFailureCodesData;
postDataFailCodes() {
  this.loadingTopFailureCodes = true;
  var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
this.arrayOnRouteArrival = [];
let user = {
    depotId: this.selectedDepot!=-1?this.selectedDepot:'',
    tripDate: date

}

let body = JSON.stringify(user);
let token = JSON.parse(localStorage.getItem('Token'));

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'idToken': token
  })
};

this.http.post(APPCONFIG.appUrl + '/dashboard/topFailureCodes', body, httpOptions)
  .subscribe(data => {
    if(data){
      if(this.interval_idFailedResionCode){
        clearTimeout(this.interval_idFailedResionCode);
      }
      this.interval_idFailedResionCode = setTimeout(()=>{
        this.postDataFailCodes()}, 120000)
    }

    console.log(data);
    this.topFailureCodes = data;
    this.topFailureCodesData = this.topFailureCodes.data;
    this.dataSourceTop3FailureCodes = new MatTableDataSource(this.topFailureCodesData);
    this.loadingTopFailureCodes = false;

  },
  err => {
    if(err){
      if(this.interval_idFailedResionCode){
        clearTimeout(this.interval_idFailedResionCode);
      }
      this.interval_idFailedResionCode = setTimeout(()=>{
        this.postDataFailCodes()}, 120000)
    }
    this.dataSourceTop3FailureCodes = new MatTableDataSource(null);
    this.loadingTopFailureCodes = false;
  });
}
serviceLevelPersentage;
serviceLevelData;
interval_idServiceLevel;
postDataServiceLevel() {
  this.loadingServiceLevel = true;
  var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
let user = {
    depotId: this.selectedDepot!=-1?this.selectedDepot:'',
    tripDate: date
}
let body = JSON.stringify(user);
let token = JSON.parse(localStorage.getItem('Token'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'idToken': token
  })
};
this.http.post(APPCONFIG.appUrl + '/dashboard/serviceLevelDetails', body, httpOptions)
  .subscribe(data => {
    console.log(data);
    this.serviceLevelData = data;
    this.serviceLevelPersentage = this.serviceLevelData.data.percentage;
    console.log(this.serviceLevelPersentage);
    this.progress1 = this.emulateProgress1();
    if(data){
      if(this.interval_idServiceLevel){
        clearTimeout(this.interval_idServiceLevel);
      }
      this.interval_idServiceLevel = setTimeout(()=>{
        this.postDataServiceLevel()}, 120000)
    }
   this.loadingServiceLevel = false;
     
  },
  err => {
    if(err){
      if(this.interval_idServiceLevel){
        clearTimeout(this.interval_idServiceLevel);
      }
      this.interval_idServiceLevel = setTimeout(()=>{
        this.postDataServiceLevel()}, 120000)
    }
    this.loadingServiceLevel = false;
  });
}
JF31Persentage;
JF31Data
interval_idJF31;
loadingJF31;
postDataJF31() {
  this.loadingJF31 = true;
  var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
let user = {
    depotId:this.selectedDepot!=-1?this.selectedDepot:'',
    tripDate: date
}
let body = JSON.stringify(user);
let token = JSON.parse(localStorage.getItem('Token'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'idToken': token
  })
};
this.http.post(APPCONFIG.appUrl + '/dashboard/epwUsedDetails', body, httpOptions)
  .subscribe(data => {
    console.log(data);
    this.JF31Data = data;
    this.JF31Persentage = this.JF31Data.data.percentage;
    console.log(this.JF31Persentage);
    this.progressJF31 = this.emulateProgressJF31();
    if(data){
      if(this.interval_idJF31){
        clearTimeout(this.interval_idJF31);
      }
      this.interval_idJF31 = setTimeout(()=>{
        
        this.postDataJF31()}, 120000)
    }
    this.loadingJF31 = false;
   
     
  },
  err => {
    if(err){
      if(this.interval_idJF31){
        clearTimeout(this.interval_idJF31);
      }
      this.interval_idJF31 = setTimeout(()=>{
        this.postDataJF31()}, 120000)
    }
    this.loadingJF31 = false;
  });
}
unpackData;
UnpackPercentage;

postDataUnpack() {
  this.loadingUnpack = true;
  var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
let user = {
    depotId: this.selectedDepot!=-1?this.selectedDepot:'',
    tripDate: date
}
let body = JSON.stringify(user);
let token = JSON.parse(localStorage.getItem('Token'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'idToken': token
  })
};
this.http.post(APPCONFIG.appUrl + '/dashboard/unpacksNotCompleted', body, httpOptions)
  .subscribe(data => {
    console.log(data);
    this.unpackData = data;
    this.UnpackPercentage = this.unpackData.data.percentage;
    console.log(this.UnpackPercentage);
    this.progressUnpack = this.emulateProgressUnpack();
    if(data){
      if(this.interval_idUnpack){
        clearTimeout(this.interval_idUnpack);
      }
      this.interval_idUnpack = setTimeout(()=>{
        this.postDataUnpack()}, 120000)
    }
   this.loadingUnpack = false;
     
  },
  err => {
    if(err){
      this.UnpackPercentage = 0;
      if(this.interval_idUnpack){
        clearTimeout(this.interval_idUnpack);
      }
      this.interval_idUnpack = setTimeout(()=>{
        this.postDataUnpack()}, 120000)
    }
    this.loadingUnpack = false;
  });
}
timeSlotData;
timeSlotPercentage;
postDataTimeSlot() {
  this.loadingTimeSlotPerform = true;
  var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
let user = {
    depotId: this.selectedDepot!=-1?this.selectedDepot:'',
    tripDate: date
}
let body = JSON.stringify(user);
let token = JSON.parse(localStorage.getItem('Token'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'idToken': token
  })
};
this.http.post(APPCONFIG.appUrl + '/dashboard/timeSlotLate', body, httpOptions)
  .subscribe(data => {
    console.log(data);
    this.timeSlotData = data;
    this.timeSlotPercentage = this.timeSlotData.data.percentage;
    console.log(this.timeSlotPercentage);
    this.progressTimeSlot = this.emulateProgressTimeSlot();
    if(data){
      if(this.interval_idTimeSlotPerform){
        clearTimeout(this.interval_idTimeSlotPerform);
      }
      this.interval_idTimeSlotPerform = setTimeout(()=>{
        
        this.postDataTimeSlot()}, 120000)
    }
   this.loadingTimeSlotPerform = false;
     
  },
  err => {
    this.timeSlotPercentage = 0;
    if(err){
      if(this.interval_idTimeSlotPerform){
        clearTimeout(this.interval_idTimeSlotPerform);
      }
      this.interval_idTimeSlotPerform = setTimeout(()=>{
        this.postDataTimeSlot()}, 120000)
    }
    this.loadingTimeSlotPerform = false;
  });
}

etaToDepotApi;
etaToDepotApiData;

postDataEtaToDepot() {
  this.loadingEtaToDepot = true;
    var date = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
    let user = {
        depotId: this.selectedDepot!=-1?this.selectedDepot:'',
        tripDate: date
  
    }
    let body = JSON.stringify(user);
    let token = JSON.parse(localStorage.getItem('Token'));
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'idToken': token
      })
    };
    this.http.post(APPCONFIG.appUrl + '/dashboard/etaToDepot', body, httpOptions)
      .subscribe(data => {
        /* console.log("ETA code Start");
          console.log(data);
          console.log("ETA code end"); */
          if(data){
            if(this.interval_idEtaToDepot){
              clearTimeout(this.interval_idEtaToDepot);
            }
            this.interval_idEtaToDepot = setTimeout(()=>{
              this.postDataEtaToDepot()}, 120000)
          }
          this.etaToDepotApi = data;
          this.etaToDepotApiData = this.etaToDepotApi.data;
          this.dataSourceEtaToDepot = new MatTableDataSource(this.etaToDepotApiData);
          this.loadingEtaToDepot = false;
         
      },
    err => {
      if(err){
        if(this.interval_idEtaToDepot){
          clearTimeout(this.interval_idEtaToDepot);
        }
        this.interval_idEtaToDepot = setTimeout(()=>{
          this.postDataEtaToDepot()}, 180000)
        
      }
      this.dataSourceEtaToDepot = new MatTableDataSource(null);
      this.loadingEtaToDepot = false;
    }
  );
  }
  

  filterSelected(){
    this.filter = [{filter:'Vehicle'},{filter:'User'}];
    this.selectedFilter = "1";
    this.selectedFilterPODSuccess = this.filter[0].filter;
    this.selectedFilterUnpack = this.filter[0].filter; 
    this.selectedFilterTimeSlot = this.filter[0].filter; 
    

    this.category = [{categoryId:"1", categoryName:"category1"},{categoryId:"2", categoryName:"category2"},
                    {categoryId:"3", categoryName:"category3"},{categoryId:"4", categoryName:"category4"},
                    {categoryId:"5", categoryName:"category5"},{categoryId:"6", categoryName:"category6"}];
    this.selctedCategory = "1";

    this.tableDemoData = [{id:'1', date:'8-03-2019', driverName:'Kshitij', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Prakesh', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Himanshu', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Sumit', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Abhishek', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Rahul', completed:'20', aborted:'5', unpack:'2', percentage:'20%'}];
  
    this.dataSource = new MatTableDataSource(this.tableDemoData);  
  }
  // handler function that receives the updated date range object
  updateRange(range: Range){
    this.range = range;
  }  
  
  // helper function to create initial presets
  setupPresets() {
 
    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }
    
    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth()+1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth()-1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    
    this.presets =  [
      {presetLabel: "Yesterday", range:{ fromDate:yesterday, toDate:today }},
      {presetLabel: "Last 7 Days", range:{ fromDate: minus7, toDate:today }},
      {presetLabel: "Last 30 Days", range:{ fromDate: minus30, toDate:today }},
      {presetLabel: "This Month", range:{ fromDate: currMonthStart, toDate:currMonthEnd }},
      {presetLabel: "Last Month", range:{ fromDate: lastMonthStart, toDate:lastMonthEnd }}
    ]
  
  }

  
  onForm() {
    this.filterSelection = new FormGroup({
      depot: new FormControl(""),
      vehicle: new FormControl(""),
      category: new FormControl(""),
    });
  }
//Bar Chart

  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels:string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thru', 'Fri', 'Sat','Sun', 'Mon', 'Tue', 'Wed', 'Thru', 'Fri', 'Sat'];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;
  public chartColors: Array<any> = [
    { // first color
      backgroundColor: 'rgba(229,3,66,1)',
      borderColor: 'rgba(229,3,66,1)',
      pointBackgroundColor: 'rgba(229,3,66,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(229,3,66,1)'
    },
    { // second color
      backgroundColor: 'rgba(3,198,229,1)',
      borderColor: 'rgba(3,198,229,1)',
      pointBackgroundColor: 'rgba(3,198,229,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(3,198,229,1)'
    },
    { // Third color
        backgroundColor: 'rgba(142,3,229,1)',
        borderColor: 'rgba(142,3,229,1)',
        pointBackgroundColor: 'rgba(142,3,229,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(142,3,229,1)'
      }];
  public barChartData:any[] = [
    {data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], label: 'On-Time'},
    {data: [28, 48, 40, 19, 86, 27, 90, 65, 59, 80, 81, 56, 55, 40  ], label: 'Early'},
    {data: [28, 48, 40, 19, 86, 27, 90, 65, 59, 80, 81, 56, 55, 40  ], label: 'Delayed'}
  ];
 
 
  // events
  /* public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  } */
 
  public randomize():void {
    // Only Change 3 values
    let data = [
      Math.round(Math.random() * 100),

      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    let clone = JSON.parse(JSON.stringify(this.barChartData));
    clone[0].data = data;
    this.barChartData = clone;
  }

  // lineChart
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },];
  public lineChartData:Array<any> = [
    [65, 59, 80, 81, 56, 55, 40]
  ];
  public lineChartLabels:Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartType:string = 'line';
  
 

 
  public randomizeType():void {
    this.lineChartType = this.lineChartType === 'line' ? 'bar' : 'line';
 
  }

  public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }
 

    ngOnDestroy(){
      if(this.interval_idUnpack){
        clearTimeout(this.interval_idUnpack);
      }
      if(this.interval_idSuccessPOD){
        clearTimeout(this.interval_idSuccessPOD);
      }
      if(this.interval_idOnRouteArrival){
        clearTimeout(this.interval_idOnRouteArrival);
      }
      if(this.interval_idTimeSlotPerform){
        clearTimeout(this.interval_idTimeSlotPerform);
      }
      if(this.interval_idEtaToDepot){
        clearTimeout(this.interval_idEtaToDepot);
      }
      if(this.interval_idFailedResionCode){
        clearTimeout(this.interval_idFailedResionCode);
      }
      
      /* if(this.interval_id){
        clearTimeout(this.interval_id);
      } */
      let promise = new Promise((resolve, reject) => {
        Promise.all([
          clearTimeout(this.interval_id),
          clearTimeout(this.interval_idEtaToDepot),
          clearTimeout(this.interval_idFailedResionCode),
          clearTimeout(this.interval_idTimeSlotPerform),
          clearTimeout(this.interval_idOnRouteArrival),
          clearTimeout(this.interval_idSuccessPOD),
          clearTimeout(this.interval_idUnpack)
      ]).then(
          () => {
              resolve();
          },
          reject
      );
      });
      return promise;
    }
  
}
