import { Injectable,Component, NgModule , Compiler}     from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';


@Injectable()
export class HttpService {
    buildVersion: string = require( '../../../../package.json').buildVersion;
    title:any;
    isConnected: Observable<boolean>;
    confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
  
    constructor(private http: Http,private router: Router, public dialog: MatDialog, private _compiler: Compiler){}
    public errorHandler(error, title){
        if(error.error == null){
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = "Failed to load data, Please refresh.";
            
        }else if (error.error.httpStatusCode == 401 || error.error.httpStatusCode == 403) {
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = "You have been logged out. Please login again";
            localStorage.clear();
            this._compiler.clearCache();
            window.location.href="./pages/auth/login-2";

        }else if (error.error.statusCode == 0 && error.error.httpStatusCode == 400) {
            this.title = "Failed to retrive/submit data";
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;
        
        }else if (error.error.statusCode == 2 && error.error.httpStatusCode == 400) {
            this.title = error.error.errorMsg;
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;
        
        }else if (error.error.status == 500) {
            if(title){ }
            else{this.title = error.error.error;}
            this.title = "Unable to fetch data, please try again.";
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;

        }else if (error.status == 400) {
            if(title){this.title = title;}
            else if(error.error.error){this.title = error.error.error;}
            else if(error.error.errorMsg){this.title = error.error.errorMsg;}
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = title;

        }else if (error.status == 500) {
            this.title = "Unable to fetch data, please try again.";
            // this.title = error.error.errorMsg;
            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;
        
        }
    }
 
    checkInternetConnection(){
        this.isConnected = Observable.merge(
            Observable.of(navigator.onLine),
            Observable.fromEvent(window, 'online').map(() => true),
            Observable.fromEvent(window, 'offline').map(() => false));
    }

    public checkSession(){
        if(!localStorage.getItem('userRedirectPath')){
            let userRedirectPath = localStorage.setItem('userRedirectPath',location.pathname);
        } 
        let timestamp = localStorage.getItem('timestamp');
        if(timestamp == null){
            return false;
        }
        let currentTimeStamp = Math.round(new Date().getTime()/1000.0);
        let diff = Math.abs(Math.floor((currentTimeStamp-parseInt(timestamp))/3600));
        // return true;
        let token = JSON.parse(localStorage.getItem('Token'));
        
        
        if(diff > 4){
            return false;
        } else {
            localStorage.setItem("timestamp", Math.round(new Date().getTime()/1000.0).toString()); // Refresh session
            return true;
        }

    }
    public loginCheckSession(){
         
        let timestamp = localStorage.getItem('timestamp');
        if(timestamp == null){
            return false;
        }
        let currentTimeStamp = Math.round(new Date().getTime()/1000.0);
        let diff = Math.abs(Math.floor((currentTimeStamp-parseInt(timestamp))/3600));
        // return true;
        let token = JSON.parse(localStorage.getItem('Token'));
        
        
        if(diff > 4){
            return false;
        } else {
            return true;
        }

    }
    public clearCacheOnVersionChange(){
        this._compiler.clearCache();
    }

     public showSessionError(){
        this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
        this.confirmDialogRefSession.componentInstance.confirmMessage = 'Session Ended, Please Login Again';
         this.router.navigateByUrl('/pages/auth/login-2');
        

        

      

        //   window.location.href = "../pages/auth/login-2" ;

        
    }


   
}
