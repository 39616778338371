import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        icon     : 'apps',
        children : [
            
            {
                id   : 'table',
                title: 'Manage Depot',
                type : 'collapsable',
                icon : 'business',
                children : [
                    {
                        id   : 'Depot',
                        title: 'Depot Details',
                        type : 'item',
                        icon : 'business',
                        url  : '/depot/list'
                    },
                    {
                        id   : 'depot',
                        title: 'Cost Per Route',
                        type : 'item',
                        icon : 'business',
                        url  : '/depot/costPerRoute'
                    }
                ]
            },
           
            
            {
                id   : 'table',
                title: 'Manage Vehicle',
                type : 'item',
                icon : 'directions_bus',
                url  : '/vehicle/list'
            },
            {
                id   : 'table',
                title: 'Manage User',
                type : 'item',
                icon : 'person',
                url  : '/user/list'
            },
            {
                id   : 'scheduler',
                title: 'Job Board',
                type : 'item',
                icon : 'departure_board',
                url  : '/scheduler/jobs'
            },
            {
                id   : 'planallocation',
                title: 'Plan Allocation',
                type : 'item',
                icon : 'trending_up',
                url  : '/planallocation/list'
            },
            {
                id   : 'jobsummary',
                title: 'Job Summary',
                type : 'item',
                icon : 'summarize',
                url  : '/jobsummary'
            },
             /* {
                id   : 'electronicpaperwork',
                title: 'electronicpaperwork',
                type : 'item',
                icon : 'today',
                url  : '/electronicpaperwork/list'
            },  */
            {
                id   : 'assetmanagement',
                title: 'Asset Management',
                type : 'item',
                icon : 'work',
                url  : '/AssetManagement/list'
            },
            {
                id   : 'reports',
                title: 'Reports',
                type : 'item',
                icon : 'insert_drive_file',
                url  : '/reports'
            },
            {
                id   : 'live-dashboard',
                title: 'Live Dashboard',
                type : 'item',
                icon : 'dashboard',
                url  : '/live-dashboard'
            },
            {
                id   : 'podQualityCheck',
                title: 'POD Quality Check',
                type : 'item',
                icon : 'fact_check',
                url  : '/podQualityCheck'
            },

            
        ]
    },

    
    
    //{
       /** id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'pages',**/
       // children: [
          //  {
               /** id      : 'authentication',
                title   : 'Authentication',
                type    : 'collapsable',
                icon    : 'lock',
                badge   : {
                    title: '1',
                    bg   : '#525e8a', 
                    fg   : '#FFFFFF'
                },**/
               // children: [ 
                   /** {
                        id   : 'login',
                        title: 'Login',
                        type : 'item',
                        url  : '/pages/auth/login'
                    },**/
                   /** {
                        id   : 'login-v2',
                        title: 'Login v2',
                        type : 'item',
                        url  : '/pages/auth/login-2'
                    },**/
                    /**{
                        id   : 'register',
                        title: 'Register',
                        type : 'item',
                        url  : '/pages/auth/register'
                    },
                    {
                        id   : 'register-v2',
                        title: 'Register v2',
                        type : 'item',
                        url  : '/pages/auth/register-2'
                    },
                    {
                        id   : 'forgot-password',
                        title: 'Forgot Password',
                        type : 'item',
                        url  : '/pages/auth/forgot-password'
                    },
                    {
                        id   : 'forgot-password-v2',
                        title: 'Forgot Password v2',
                        type : 'item',
                        url  : '/pages/auth/forgot-password-2'
                    },
                    {
                        id   : 'reset-password',
                        title: 'Reset Password',
                        type : 'item',
                        url  : '/pages/auth/reset-password'
                    },
                    {
                        id   : 'reset-password-v2',
                        title: 'Reset Password v2',
                        type : 'item',
                        url  : '/pages/auth/reset-password-2'
                    },
                    {
                        id   : 'lock-screen',
                        title: 'Lock Screen',
                        type : 'item',
                        url  : '/pages/auth/lock'
                    },
                    {
                        id   : 'mail-confirmation',
                        title: 'Mail Confirmation',
                        type : 'item',
                        url  : '/pages/auth/mail-confirm'
                    }**/
             //   ]
    //}, 
            
            /**{
                id      : 'errors',
                title   : 'Errors',
                type    : 'collapsable',
                icon    : 'error',
                children: [
                    {
                        id   : '404',
                        title: '404',
                        type : 'item',
                        url  : '/pages/errors/error-404'
                    },
                    {
                        id   : '500',
                        title: '500',
                        type : 'item',
                        url  : '/pages/errors/error-500'
                    }
                ]
            },
            
            {
                id   : 'maintenance',
                title: 'Maintenance',
                type : 'item',
                icon : 'build',
                url  : '/pages/maintenance'
            },
            {
                id      : 'pricing',
                title   : 'Pricing',
                type    : 'collapsable',
                icon    : 'attach_money',
                children: [
                    {
                        id   : 'style-1',
                        title: 'Style 1',
                        type : 'item',
                        url  : '/pages/pricing/style-1'
                    },
                    {
                        id   : 'style-2',
                        title: 'Style 2',
                        type : 'item',
                        url  : '/pages/pricing/style-2'
                    },
                    {
                        id   : 'style-3',
                        title: 'Style 3',
                        type : 'item',
                        url  : '/pages/pricing/style-3'
                    }
                ]
            },
            {
                id   : 'profile',
                title: 'Profile',
                type : 'item',
                icon : 'person',
                url  : '/pages/profile'
            },
            {
                id   : 'search',
                title: 'Search',
                type : 'item',
                icon : 'search',
                url  : '/pages/search'
            },
            **/
            
        ];
   // },

//];
