import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import date_box from '../../../../node_modules/devextreme/ui/date_box';
import {MatPaginator, MatSort, MatTableDataSource, PageEvent} from '@angular/material';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APPCONFIG } from '../config';




@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
 
})
export class ReportingComponent implements OnInit {
  filterSelection:any;
  depots:any;
  vehicles:any;
  category:any;
  selectedDepot:any;
  selectedVehicle:any;
  selctedCategory:any;
  range:Range = {fromDate:new Date(), toDate: new Date()};
  options:NgxDrpOptions;
  getlength = 100;
  presets:Array<PresetItem> = [];
  tableDemoData:any;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['id', 'date', 'driverName' , 'completed', 'aborted'];

  AdminForm;
  report_json = {};
  padData = []; 
  public rows:any;
  

podData:any = [];
  
  constructor(private http: HttpClient) {

 
   }

  ngOnInit() {
    this.AdminForm = new FormGroup({
      depotName: new FormControl("", Validators.compose([Validators.required,Validators.pattern("")])),
      vechile: new FormControl("", Validators.compose([Validators.required,Validators.pattern("")])),
      category: new FormControl("",Validators.compose([Validators.required,Validators.pattern("")])),
      //fromToDate: new FormControl("",Validators.compose([Validators.required,Validators.pattern("")]))
   });
    this.filterSelected();
    
    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth()-2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth()+1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth()-1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth()+2, 0);
 
    this.setupPresets();
    this.options = {
                    presets: this.presets,
                    format: 'mediumDate',
                    range: {fromDate:today, toDate: today},
                    applyLabel: "Submit",
                    calendarOverlayConfig: {
                      shouldCloseOnBackdropClick: false
                    }
                    // cancelLabel: "Cancel",
                    // excludeWeekends:true,
                    // fromMinMax: {fromDate:fromMin, toDate:fromMax},
                    // toMinMax: {fromDate:toMin, toDate:toMax}
                  };
  }
  
  // handler function that receives the updated date range object
  updateRange(range: Range){
    this.range = range;
  }  
  
  // helper function to create initial presets
  setupPresets() {
 
    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }
    
    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth()+1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth()-1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    
    this.presets =  [
      {presetLabel: "Yesterday", range:{ fromDate:yesterday, toDate:today }},
      {presetLabel: "Last 7 Days", range:{ fromDate: minus7, toDate:today }},
      {presetLabel: "Last 30 Days", range:{ fromDate: minus30, toDate:today }},
      {presetLabel: "This Month", range:{ fromDate: currMonthStart, toDate:currMonthEnd }},
      {presetLabel: "Last Month", range:{ fromDate: lastMonthStart, toDate:lastMonthEnd }}
    ]
  
  }

  filterSelected(){
    this.depots = [{depotId:"123", depotName:"Depot1"},{depotId:"124", depotName:"Depot2"},
                  {depotId:"125", depotName:"Depot6"},{depotId:"126", depotName:"Depot3"},
                  {depotId:"127", depotName:"Depot4"},{depotId:"128", depotName:"Depot5"}];
    this.selectedDepot = "123";
    this.vehicles = [{vehicleId:"1", VehicleName:"vehicle1"},{vehicleId:"2", VehicleName:"vehicle2"},
                    {vehicleId:"3", VehicleName:"vehicle3"},{vehicleId:"4", VehicleName:"vehicle4"},
                    {vehicleId:"5", VehicleName:"vehicle5"},{vehicleId:"6", VehicleName:"vehicle6"}];
    this.selectedVehicle = "1";
     this.category = [{categoryId:"1", categoryName:"category1"},{categoryId:"2", categoryName:"category2"},
                    {categoryId:"3", categoryName:"category3"},{categoryId:"4", categoryName:"category4"},
                    {categoryId:"5", categoryName:"category5"},{categoryId:"6", categoryName:"category6"}];
    this.selctedCategory = "1";

    this.tableDemoData = [{id:'1', date:'8-03-2019', driverName:'Kshitij', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Prakesh', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Himanshu', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Sumit', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Abhishek', completed:'20', aborted:'5', unpack:'2', percentage:'20%'},
                         {id:'1', date:'8-03-2019', driverName:'Rahul', completed:'20', aborted:'5', unpack:'2', percentage:'20%'}];

                         
  
   // this.dataSource = new MatTableDataSource(this.tableDemoData);  
  }
  onForm() {
    this.filterSelection = new FormGroup({
      depot: new FormControl(""),
      vehicle: new FormControl(""),
      category: new FormControl(""),
    });
  }

  filterData(){
  /*   console.log(this.selectedDepot);
    console.log(this.selectedVehicle);
    console.log(this.selctedCategory);
    console.log(this.range); */

  }

  savePodData(res){

    if(res && Object.keys(res).length > 0 ){
      this.podData = res;
      this.rows=[];
      this.rows['offSet'] = res.offSet;
      if(this.padData) {
        //console.log("asjfhsfklvfs");
          if(this.podData['items']) {
            console.log(this.podData['items']);
              let totalCount = 0;
          if(parseInt(res.offSet) == 0) {
              localStorage.setItem('totalCount',res.itemCount);
              totalCount = parseInt(res.itemCount);
          } else {
              totalCount = parseInt(localStorage.getItem('totalCount'));
          }
          this.rows['total_count'] = totalCount;
          
          for (let data in this.podData['items']) {
             
                  this.rows[data] = {};
                  this.rows[data]['id'] = this.podData['items']['id']; 
                  this.rows[data]['address1'] = this.podData['items']['address1'];
                  this.rows[data]['postcode'] = this.podData['items']['postcode'];
                  this.rows[data]['user1'] = this.podData['items']['user1'];
                  this.rows[data]['jobStatus'] = this.podData['items']['jobStatus'];
              }
        
          }
      }

      this.dataSource = new MatTableDataSource(this.rows);  
      console.log("datasource: " + this.dataSource);

      let finalRows = [];
      for(let f in this.rows){
          if(f != 'offSet' && f !='total_count' && this.rows[f]){
              finalRows.push(this.rows[f]);
          }
          if(f == 'offSet'){
              finalRows['offSet'] = this.rows['offSet'];
          }
          if(f == 'total_count'){
              finalRows['total_count'] = this.rows['total_count'];
          }
      }
      this.rows = finalRows;
  }
  }

  
}
