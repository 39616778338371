import { Component, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../../app/main/services/http.service';
import { APPCONFIG } from '../../../app/main/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'update-trip-dialog',
    templateUrl: './update-trip-dialog.component.html',
    styleUrls: ['./update-trip-dialog.component.scss'],
    providers: [HttpService, DatePipe]
})
export class UpdateTripDialogComponent {
  confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isValidDate=false;
  selectedDate='';
  selectedVehicle='';
  vehiclesResult:any;
  errorMsg='';
  result;

  public title: string;
  public tripDetails = {planId:'', tripId:'', vehicleId:'', driverId:'', depotId:0, tripDate:new Date(), updated:false, vehicleMandatory:false};
  public originalDateStr='';
  public vehicles=[];


  /**
   * Constructor
   *
   * @param {MatDialogRef<UpdateTripDialogComponent>} dialogRef
   */

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, private cdr:ChangeDetectorRef, 
      private http: HttpClient, private http1: HttpService, private datePipe: DatePipe, 
      public dialogRef: MatDialogRef<UpdateTripDialogComponent>
  ) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    //
  }
  closeDialog() {
      this.dialogRef.close(this.tripDetails);
  }

  dateChanged(event) {
    this.selectedDate = this.datePipe.transform(event.value._d, 'yyyy-MM-dd');
    this.isValidDate = (this.selectedDate != this.originalDateStr);

    //Get Vacant vehicle for this date.
    if(this.tripDetails.vehicleMandatory) {
      this.getVehicleData(this.tripDetails.depotId, this.selectedDate);
    }
  }

  initMoveShipment() {
      if(!this.isValidDate || !this.selectedDate || (this.tripDetails.vehicleMandatory && !this.selectedVehicle)) return;

      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });
      var title = "Are you sure you want to move the shipment to '"+this.selectedDate+"' ?";
      this.confirmDialogRef.componentInstance.confirmMessage = title;
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.moveShipment();
        }
      });
  }
  moveShipment() {
    this.spinner.show();
    let body = JSON.stringify({updatedDate:this.selectedDate, planId:this.tripDetails.planId, depotId:this.tripDetails.depotId, 
              tripId:this.tripDetails.tripId, vehicleId:this.tripDetails.vehicleId, newVehicleId:this.selectedVehicle});
    let token = JSON.parse(localStorage.getItem('Token'));
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'idToken': token }) };

    this.http.post(APPCONFIG.appUrl + '/plan/updateTripDate', body, httpOptions)
      .subscribe(data => {
        this.result = data;
        if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201) {
          this.tripDetails.updated=true;

          this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
          this.confirmDialogRefSession.componentInstance.confirmMessage = "Shipment moved successfully";
          this.confirmDialogRefSession.afterClosed().subscribe(result => {
              this.closeDialog(); // also close the Update trip Dialog
          });

          this.cdr.detectChanges();
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        let title = "Failed to update."
        this.http1.errorHandler(err, title);
      });
  }

  getVehicleData(selectedDepot, date) {
    this.errorMsg = '';
    this.vehicles=[];
    let token = JSON.parse(localStorage.getItem('Token'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'idToken': token
      })
    };
    this.spinner.show();
    return this.http.get(APPCONFIG.appUrl + '/vehicle/list/vacant/'+selectedDepot+'/'+date, httpOptions)
      .subscribe(result => {
        this.vehiclesResult = result;
        if(this.vehiclesResult.data) {
          for(let vehicle of this.vehiclesResult.data){
            this.vehicles.push({'vehicleId':vehicle.vehicleId,'VehicleName':vehicle.vehicleRegistrationId});
          }
        }else {
          this.errorMsg = 'No Vacant Vehicles for the selected date';
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.errorMsg = "Failed to get the vehicles, please retry."
      });
  }
}
