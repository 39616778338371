import { Component , Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APPCONFIG } from '../../../../config';
import { ActivatedRoute } from '@angular/router';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HttpService } from '../../../../services/http.service';
import { MatTableDataSource } from '@angular/material';
import { epwComponent } from '../epw.component';
import { BehaviorSubject, Observable } from 'rxjs';
/**
 * @title Dialog elements
 */

@Component({
  //selector: 'dialog-elements-example-dialog',
  templateUrl: 'abort_job.component.html',
  providers: [ HttpService]
})
@Injectable()
export class AbortJobDialog {
  reasonCode: any;
  dialogCloseAction: BehaviorSubject<any>;
  reasons: any;
  title:any;
  result:any;
  message;
  onClose: {};
  selectedCodes:any;
  abortStatusDisable:any;
  postObject:any = {};
  jobParamId:any;
  confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
  abortJob: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http1: HttpService, private _router: Router, private spinner: NgxSpinnerService,
    public _matDialogRef: MatDialogRef<epwComponent>, private route: ActivatedRoute, public dialog: MatDialog, private http: HttpClient) 
    {
      this.dialogCloseAction = new BehaviorSubject({});
      
     }
  ngOnInit() {
    this.getCombineRiskAssismentData();
    this.onClose = {data: 'false'};
  }

  abortReasonCode() {
    if(this.data.abortJob) {
      this.postObject = {abortCode: this.selectedCodes, jobId: JSON.parse(this.data.jobId)}; 
    } else {
      this.postObject = {abortCode: this.selectedCodes, jobId: this.data.rowCounter.itemPrimaryId}; 
    }
    this.postData();
    
  }
  getRiskAssismentData() {
    let uid = JSON.parse(localStorage.getItem('uid'));
    let token = 'ou*7nMm95ON0s??6FX0/[J1PQt%Lwj0I';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'idToken': token
      })
    };
    let partUrl = '';
      if(this.data.isSecondaryFreight) {
        partUrl = '/epw/deliverymissed/secondaryFreight';
      }else  {
        partUrl = this.data.deliveryReason? '/epw/deliverymissed' : '/epw/abortCodes';
      }
    return this.http.get(APPCONFIG.appUrl+partUrl, httpOptions);
  }

  
  getCombineRiskAssismentData() {
    this.getRiskAssismentData().subscribe(result => {
      this.reasonCode = result;
      this.reasons = this.reasonCode.data;
    },
    err => {
      let title = "Failed to perform this action";
      this.http1.errorHandler(err, title);
});
  }
  
  postData(){
    this.onClose = {data: 'true', reasonCode: this.postObject.abortCode, jobId: this.postObject.jobId};
    this.dialogCloseAction.next(this.onClose);
    let body = JSON.stringify(this.postObject);
    let token = this.data.isAdmin ? JSON.parse(localStorage.getItem('Token')) : 'ou*7nMm95ON0s??6FX0/[J1PQt%Lwj0I';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'idToken': token
      })
    };
    if(this.data.abortJob) {
      console.log('Abort secondaryFreight job ? '+this.data.isSecondaryFreight+', isAdmin:'+this.data.isAdmin);
      let partUrl = '';
      if(this.data.isSecondaryFreight) {
        partUrl = this.data.isAdmin? '/epw/abortSecondaryFreightJobForAdmin' : '/epw/abortSecondaryFreightJob';
      }else  {
        partUrl = this.data.isAdmin? '/epw/abortJobForAdmin' : '/epw/abortJob';
      }
      this.http.post(APPCONFIG.appUrl+partUrl, body, httpOptions)
      .subscribe(data => {
          this.result = data;
          this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
          this.confirmDialogRefSession.componentInstance.confirmMessage = "Successfully Aborted";
        },
        err => {
          this.onClose = {data: 'false'};
          let title = "Failed to perform this action";
          this.http1.errorHandler(err, title);
            this.spinner.hide();
        }
  
      );
    }else{
      this.onClose = {data: 'true', reasonCode: this.postObject.abortCode};
      this.dialogCloseAction.next(this.onClose);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.dialogCloseAction.next(this.onClose);
    this._matDialogRef.close(this.onClose);
}

}

