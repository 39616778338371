import { Component, OnInit } from '@angular/core';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { Observable } from 'rxjs';

@Component({
 // selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  readonly progress: Observable<number>;
  readonly progress1: Observable<number>;
    
  constructor() { 
    this.progress = this.emulateProgress();
    this.progress1 = this.emulateProgress1();
  }
  ngOnInit(){
    
  }
  emulateProgress() {
    return new Observable<number>(observer => {
      let val = 0;
      const interval = setInterval(() => {
        if (val < 75) {
          val++;
        } 

        observer.next(val);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    });
  }
  emulateProgress1() {
    return new Observable<number>(observer => {
      let val = 0;
      const interval = setInterval(() => {
        if (val < 65) {
          val++;
        } 

        observer.next(val);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    });
  }


}
