import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';

import { sessionConfirmDialogComponent } from './confirm-dialog-session.component';

@NgModule({
    declarations: [
        sessionConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        sessionConfirmDialogComponent
    ],
})
export class sessionConfirmDialogModule
{
}
